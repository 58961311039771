import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { usePlanData, useSetIsLoading, useSetPlanData } from 'hooks/useGlobalState'

import Loader from 'common/Loader'
import PlanPreview from './PlanPreview'
import PayPalForm from 'components/PayPalForm'
import RightSectionLoader from 'common/RightSectionLoader'

import request from 'utils/request'
import PaymentSwitcher from './PaymentSwitcher'

export default React.memo(function BillingForm() {
  const { search } = useLocation()
  const setIsLoading = useSetIsLoading((prev, next) => next, [])
  const planData = usePlanData()
  const setPlanData = useSetPlanData((prev, next) => next, [])

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const plan = searchParams.get('plan')
    const billed = searchParams.get('billed')
    if (plan) {
      setIsLoading(true)
      request({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/patients/plans/${plan}`,
        params: {
          billed
        }
      })
        .then(({ data: { data } }) => {
          setIsLoading(false)
          setPlanData(data.plan)
        })
        .catch((error) => {
          setIsLoading(false)
          console.log(error)
        })
    }
  }, [search, setIsLoading, setPlanData])

  if (!planData) {
    return (
      <StyledWrapper >
        <Loader />
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper>
      <div className='form-container'>
        <PlanPreview />

        <PaymentSwitcher />
        <PayPalForm />
      </div>

      <RightSectionLoader />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  margin-left: 50%;
  width: 50%;
  padding: 9% 0 9% 6.5%;
  margin-top: auto;
  margin-bottom: auto;
  @media screen and (max-width: 1000px) {
    margin-left: 0;
    width: 100%;
    padding: 5rem 4% 10rem;
  }
  .form-title {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  .form-container {
    max-width: 40rem;
    margin-top: 3rem;
   display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 1000px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
      max-width: initial;
    }
    .submit-button {
      margin-top: 2.5rem;
      padding: 1.6rem 5rem;
    }
  }
`
