import React from 'react'
import styled from 'styled-components'

export default React.memo(function InputWithLabel({
  label, className = '', placeholder = '', type = 'text', value, changeHandler,
  isRequred, isValid, errors = [], clientErrors = []
}) {
  const [isChanged, setIsChanged] = React.useState(false)

  const handleChange = React.useCallback(e => {
    changeHandler(e.target.value)
    setIsChanged(true)
  }, [changeHandler])

  return (
    <div className={className}>
      {label && (
        <p className='g-mb-10 g-input-label'>
          {label}
          {isRequred && <span className='required'>*</span>}
        </p>
      )}
      <StyledInput
        type={type}
        isValid={isValid}
        isChanged={isChanged}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
      <div>
        {errors.map(error => (<StyledError key={error}>{error}</StyledError>))}
        {!isValid && clientErrors.map(error => (<StyledError key={error}>{error}</StyledError>))}
      </div>
    </div>
  )
})

const StyledInput = styled.input`
  display: block;
  width: 100%;
  font-size: 1.3rem;
  line-height: 3rem;
  outline: none;
  padding: 0.8rem 1.1rem;
  border: solid 1px #e9ebef;
  border-radius: 2px;
  &::placeholder {
    font-weight: 300;
  }
  &:hover {
    border: solid 1px #ccc;
  }
  &:focus {
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
  }
  ${({ isValid, isChanged }) => !isValid && isChanged && `
    border-color: #f86060;
    &::placeholder {
      color: #f86060;
    }
    &:hover {
      border: solid 1px #f86060;
    }
    &:focus {
      box-shadow: 0 0 0 0.1rem #f86060;
    }
  `};
`

const StyledError = styled.div`
  color: #f86060;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`
