import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { usePlanData, useSetIsLoading, useSetPlanData } from 'hooks/useGlobalState'

import Loader from 'common/Loader'
import Discount from './Discount'
import PlanPreview from './PlanPreview'
import PaymentSwitcher from './PaymentSwitcher'
import PayPalForm from './PayPalForm'

import request from 'utils/request'

export default React.memo(function ProgramsForm () {
  const { search } = useLocation()
  const setIsLoading = useSetIsLoading((prev, next) => next, [])
  const planData = usePlanData()
  const setPlanData = useSetPlanData((prev, next) => next, [])
  const clearPlanData = useSetPlanData(() => null, [])

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const plan = searchParams.get('plan')
    const billed = searchParams.get('billed')
    const discount = searchParams.get('discount')
    if (plan) {
      setIsLoading(true)
      request({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/patients/programs/${plan}`,
        params: {
          billed,
          discount
        }
      })
        .then(({ data: { data } }) => {
          setIsLoading(false)
          setPlanData(data.plan)
        })
        .catch((error) => {
          setIsLoading(false)
          console.log(error)
        })
    }
    return clearPlanData
  }, [search, setPlanData, clearPlanData, setIsLoading])

  if (!planData) {
    return (
      <StyledWrapper>
        <Loader />
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper>
      <Discount />
      <PlanPreview />
      <PaymentSwitcher />
      <PayPalForm />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 57.5rem;
  margin-top: 6rem;
  padding: 4.8rem;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 480px) {
    padding: 2.4rem;
  }
`
