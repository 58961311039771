import React from 'react'
import styled, { css } from 'styled-components'

export default React.memo(function CustomButton ({
  children, clickHandler = () => {}, className = '', disabled, inversion, isLoading
}) {
  const handleClick = React.useCallback((event, ...args) => {
    if (disabled) return
    clickHandler(event, ...args)
  }, [disabled, clickHandler])

  if (isLoading) return <StyledLoader className={className} />

  return (
    <StyledWrapper
      inversion={inversion}
      disabled={disabled}
      className={className}
      onClick={handleClick}
    >
      {children}
    </StyledWrapper>
  )
})

const StyledLoader = styled.div`
  display: inline-block;
  align-self: flex-start;
  height: 4.3rem;
  width: 14rem;
  background: #e9ebef;
  border-radius: 2.4rem;
`

const StyledWrapper = styled.div`
  display: inline-block;
  align-self: flex-start;
  border: solid 2px #16123f;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.4rem;
  padding: 1.1rem 4rem;
  transition: all 0.2s;
  background: #979797;
  border: solid 2px #979797;
  border-radius: 2.4rem;
  font {
    color: #fff;
  }
  ${({ disabled }) => !disabled && css`
    background: #16123f;
    border: solid 2px #16123f;
    cursor: pointer;
    &:hover {
      background: #fff;
      color: #16123f;
      font {
        color: #16123f;
      }
    }
    &:active {
      box-shadow: 0 0 0 0.3rem rgba(22, 18, 63, 0.25);
    }
  `}
  ${({ inversion, disabled }) => !disabled && inversion && css`
    color: #16123f;
    font {
      color: #16123f;
    }
    background: #fff;
    &:hover {
      background: #16123f;
      color: #fff;
      font {
        color: #fff;
      }
    }
  `}
`
