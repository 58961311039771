import React from 'react'
import styled from 'styled-components'

import { useLocation } from 'react-router-dom'
import { useCouponCode, useSetCouponCode, useSetIsCouponCodeValid, useSetPlanData, useSetCouponData } from 'hooks/useGlobalState'

import InputWithLabel from 'common/InputWithLabel'
import LinkButton from 'common/LinkButton'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

export default React.memo(function СouponForm() {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const plan = searchParams.get('plan')
  const billed = searchParams.get('billed')

  const couponCode = useCouponCode()

  const setCouponCode = useSetCouponCode((prev, next) => next, [])
  const setPlanId = useSetPlanData((prev, id) => ({ ...prev, payment_id: id }), [])
  const setIsCouponCodeValid = useSetIsCouponCodeValid((prev, next) => next, [])
  const setCouponData = useSetCouponData((prev, coupon) => ({ ...prev, coupon }), [])
  const [errorMessages, setErrorMessages] = React.useState([])

  const submitСoupon = React.useCallback((e) => {
    e.preventDefault()
    request({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/patients/plans/${plan}`,
      params: {
        billed,
        coupon: couponCode
      }
    })
      .then(({ data: { data } }) => {
        setPlanId(data.plan)
        setCouponData(data.plan.coupon)
        setIsCouponCodeValid(true)
        createNotification({
          message: 'Coupon successfully applied',
          type: 'success'
        })
        setErrorMessages([])
      })
      .catch((error) => {
        console.log(error)
        setIsCouponCodeValid(false)
        setErrorMessages((prev) => [...prev, 'This code is not valid. Check your entry and try again.'])
        createNotification({
          message: 'Invalid coupon code',
          type: 'error'
        })
      })
  }, [plan, billed, couponCode, setPlanId, setIsCouponCodeValid, setCouponData])

  const handleInputChange = (value) => {
    setCouponCode(value)
    setErrorMessages([])
  }

  return (
    <StyledWrapper onSubmit={submitСoupon}>
      <InputWithLabel
        className='input'
        label='Redeem a coupon code'
        placeholder='Enter your code'
        isValid={couponCode.length !== 0}
        value={couponCode}
        changeHandler={handleInputChange}
        errors={errorMessages}
      />

      <LinkButton
        className='apply'
        disabled={!couponCode}
        clickHandler={submitСoupon}
      >
        Apply
      </LinkButton>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 12px;

align-self: stretch;
  .input {
    width: 100%;
  }
  .apply {
    margin-top: 38px;
  }
`
