import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Logo } from 'assets/logo.svg'
import { ReactComponent as Celebrate } from 'assets/celebrate.svg'
import { CibaClassicTheme } from 'themes/cibaClassic'

import CustomButton from 'common/CustomButton'

export default function EmailVerified() {
  const navigateToDashboard = React.useCallback(() => {
    window.location.href = `${process.env.REACT_APP_ENV_URL}/login`
  }, [])
  return (
    <CibaClassicTheme>
      <StyledWrapper>
        <Logo className='logo' />

        <Celebrate className='celebrate' />

        <p className='title'>
          Account Email Address Confirmed
        </p>
        <p className='text'>
          Congratulations! Your account email address has been successfully confirmed.
        </p>

        <CustomButton
          className='g-mt-30'
          clickHandler={navigateToDashboard}
        >
          To the Login Page
        </CustomButton>
      </StyledWrapper>
    </CibaClassicTheme>
  )
}

const StyledWrapper = styled.div`
  max-width: 1200px;
  margin: 5.5rem auto;
  text-align: center;
  padding: 0 2rem;
  .logo {
    display: block;
    g {
      fill: #16123f;
    }
  }
  .celebrate {
    display: block;
    width: 10rem;
    height: 10rem;
    margin: 10rem auto 0;
  }
  .title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-top: 2rem;
  }
  .text {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 2.5rem;
  }
`
