import React from 'react'
import styled, { css } from 'styled-components'

export default React.memo(function LinkButton({
  children, clickHandler = () => { }, className = '', disabled, inversion, isLoading
}) {
  const handleClick = React.useCallback((event, ...args) => {
    if (disabled) return
    clickHandler(event, ...args)
  }, [disabled, clickHandler])

  if (isLoading) return <StyledLoader className={className} />

  return (
    <StyledWrapper
      disabled={disabled}
      className={className}
      onClick={handleClick}
    >
      {children}
    </StyledWrapper>
  )
})

const StyledLoader = styled.div`
  display: inline-block;
  align-self: flex-start;
  height: 4.3rem;
  width: 14rem;
  background: #e9ebef;
  border-radius: 2.4rem;
`

const StyledWrapper = styled.div`
  color: #4338CA;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
  text-underline-offset: 30%;
  cursor: pointer;
    :hover {
      color: #312E81;
    }

  ${({ disabled }) => disabled && css`
    color: #6B7280;
    text-decoration: none;
    &:hover {
      color: #6B7280;
      cursor: not-allowed;
    }
  `}

`
