import React from 'react'
import Routes from 'Routes'

import { Amplify } from "aws-amplify";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const USERPOOL_ID = process.env.REACT_APP_USERPOOL_ID;

Amplify.configure({
  region: "us-east-2",
  userPoolId: `${USERPOOL_ID}`,
  userPoolWebClientId: `${CLIENT_ID}`,
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

export default function App() {
  return (
    <Routes />
  )
}
