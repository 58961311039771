import React from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'

import { EMAIL_VERIFICATION } from 'config/routes'

import {
  globalContext, useFreemiumForm, useSetFreemiumForm, useErrors, useSetErrors, useSetVerificationEmail
} from 'hooks/useGlobalState'

import FormTitle from 'common/FormTitle'
import InputWithLabel from 'common/InputWithLabel'
import CustomCheckbox from 'common/CustomCheckbox'
import CustomButton from 'common/CustomButton'

import validatePassword from 'utils/validatePassword'
import request from 'utils/request'

function existValidation(value) {
  return !!value
}

function emailValidation(value) {
  const emailRe = /\S+@\S+\.\S+/
  return emailRe.test(value)
}

export default function FreemiumSignUpForm() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const code = searchParams.get('code')
  const { freemiumForm } = React.useContext(globalContext)
  const errors = useErrors()
  const setErrors = useSetErrors((prev, next) => next, [])
  const setVerificationEmail = useSetVerificationEmail((prev, next) => next, [])
  const [validate, setValidate] = React.useState(false)
  const [isSended, setIsSended] = React.useState(false)

  const email = useFreemiumForm(current => get(current, 'email', ''))
  const isEmailValid = emailValidation(email)
  const setEmail = useSetFreemiumForm((prev, next) => ({ ...prev, email: next }), [])

  const password = useFreemiumForm(current => get(current, 'password', ''))
  const passwordValidation = validatePassword(password)
  const isPasswordValid = passwordValidation.isValid
  const setPassword = useSetFreemiumForm((prev, next) => ({ ...prev, password: next }), [])

  const firstName = useFreemiumForm(current => get(current, 'first_name', ''))
  const isFirstNameValid = existValidation(firstName)
  const setFirstName = useSetFreemiumForm((prev, next) => ({ ...prev, first_name: next }), [])

  const lastName = useFreemiumForm(current => get(current, 'last_name', ''))
  const isLastNameValid = existValidation(lastName)
  const setLastName = useSetFreemiumForm((prev, next) => ({ ...prev, last_name: next }), [])

  const termsAgree = useFreemiumForm(current => get(current, 'terms_agree', false))
  const setTermsAgree = useSetFreemiumForm((prev, next) => ({ ...prev, terms_agree: next }), [])

  const submitHandler = React.useCallback((e) => {
    e.preventDefault()
    setValidate(true)
    setIsSended(true)
    request({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/patients/referral/register`,
      data: {
        ...freemiumForm.value,
        referrer_code: code
      }
    })
      .then(() => {
        setVerificationEmail(freemiumForm.value.email)
        navigate(`/${EMAIL_VERIFICATION}`)
      })
      .catch((err) => {
        setErrors(err.response.data.errors)
      })
      .finally(() => setIsSended(false))
  }, [setValidate, setErrors, freemiumForm, setIsSended, setVerificationEmail, navigate, code])

  if (!code) return <Navigate to='/' />

  return (
    <StyledWrapper>
      <FormTitle
        title='Sign up'
        subTitle='Please fill in this form to create new account'
      />

      <form onSubmit={submitHandler}>
        <InputWithLabel
          label='Email'
          placeholder='Enter your email'
          isRequred
          isValid={!validate || isEmailValid}
          value={email}
          changeHandler={setEmail}
          errors={errors['email']}
        />
        <InputWithLabel
          className='g-mt-15'
          label='Password'
          placeholder='Enter your password'
          type='password'
          isRequred
          isValid={!validate || isPasswordValid}
          value={password}
          changeHandler={setPassword}
          clientErrors={[passwordValidation.message]}
        />
        <InputWithLabel
          className='g-mt-15'
          label='First name'
          placeholder='Enter your first name'
          isRequred
          isValid={!validate || isFirstNameValid}
          value={firstName}
          changeHandler={setFirstName}
          errors={errors['first_name']}
        />
        <InputWithLabel
          className='g-mt-15'
          label='Last name'
          placeholder='Enter your last name'
          isRequred
          isValid={!validate || isLastNameValid}
          value={lastName}
          changeHandler={setLastName}
          errors={errors['last_name']}
        />
        <CustomCheckbox
          className='confirmation g-mt-15'
          label={(
            <p>
              <span>I am over 18 and agree to the </span>
              <a
                className='g-link'
                href='https://cibahealth.com/terms-of-use/'
                rel='noopener noreferrer'
                target='_blank'
              >
                terms of use
              </a>
              <span> and </span>
              <a
                className='g-link'
                href='https://cibahealth.com/privacy-policy/'
                rel='noopener noreferrer'
                target='_blank'
              >
                privacy policy
              </a>.
            </p>
          )}
          checked={termsAgree}
          changeHandler={setTermsAgree}
        />
        <CustomButton
          className='g-mt-15'
          disabled={!termsAgree || isSended}
          clickHandler={submitHandler}
        >
          Confirm
        </CustomButton>
      </form>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: relative;
  margin-left: 50%;
  width: 50%;
  padding: 9% 0 9% 6.5%;
  @media screen and (max-width: 1000px) {
    margin-left: 0;
    width: 100%;
    padding: 2rem 4% 4.5rem;
  }
  .form-title {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  form {
    max-width: 40rem;
    margin-top: 3rem;
    @media screen and (max-width: 1000px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
      max-width: initial;
    }
    .confirmation {
      font-size: 1.2rem;
    }
  }
`
