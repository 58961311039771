import React from 'react'
import styled from 'styled-components'

export default React.memo(function ThriveTemplate({ children }) {
  return (
    <StyledWrapper>
      {children}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  background-color: #F4F5F7;
   @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`
