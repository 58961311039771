import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Logo } from 'assets/logo.svg'

export default React.memo(function LeftSideHeader() {

  return (
    <StyledWrapper>
      {<Logo className='logo' />}

      <a className='login-link' href={`${process.env.REACT_APP_ENV_URL}/login`}>Login</a>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 14.4rem;
    height: 3.8rem;
  }
  .login-link {
    display: none;
    font-size: 1.6rem;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    @media screen and (max-width: 1000px) {
      display: block;
    }
  }
`