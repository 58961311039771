import React from 'react'
import { BrowserRouter as Router, Route, Routes as RouterRoutes, Navigate, useLocation } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import {
  BILLING, PROGRAMS, CORPORATE_SIGN_UP, REFERRAL_SIGN_UP, THANK_YOU, THRIVE_THANK_YOU, EMAIL_VERIFICATION,
  CORPORATE_VERIFY, EMAIL_VERIFIED, COMPANY, JIB, EBI, RESULT, REJECT
} from 'config/routes'

import CorporateSignUp from 'containers/CorporateSignUp'
import Billing from 'containers/Billing'
import Programs from 'containers/Programs'
import ThankYou from 'containers/ThankYou'
import ThriveThankYou from 'containers/ThriveThankYou'
import EmailVarification from 'containers/EmailVarification'
import CorporateVerify from 'containers/CorporateVerify'
import FreemiumSignUp from 'containers/FreemiumSignUp'
import EmailVerified from 'containers/EmailVerified'
import JIBpage from 'containers/company/JIB'
import EBIpage from 'containers/company/EBI'
import QuestionnaireResult from 'containers/company/QuestionnaireResult'
import QuestionnaireRejectResult from 'containers/company/QuestionnaireRejectResult'

const ScrollToTop = React.memo(function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
});

export default function AppRoutes() {
  return (
    <Router>
      <ScrollToTop />
      <RouterRoutes>
        <Route path={`/${BILLING}`} element={<Billing />} />
        <Route path={`/${PROGRAMS}`} element={<Programs />} />
        <Route path={`/${THANK_YOU}`} element={<ThankYou />} />
        <Route path={`/${THRIVE_THANK_YOU}`} element={<ThriveThankYou />} />
        <Route path={`/${CORPORATE_SIGN_UP}`} element={<CorporateSignUp />} />
        <Route path={`/${REFERRAL_SIGN_UP}`} element={<FreemiumSignUp />} />
        <Route path={`/${EMAIL_VERIFICATION}`} element={<EmailVarification />} />
        <Route path={`/${CORPORATE_VERIFY}`} element={<CorporateVerify />} />
        <Route path={`/${EMAIL_VERIFIED}`} element={<EmailVerified />} />
        <Route path={`/${COMPANY}/${JIB}`} element={<JIBpage />} />
        <Route path={`/${COMPANY}/${EBI}`} element={<EBIpage />} />
        <Route path={`/${COMPANY}/${JIB}/${RESULT}`} element={<QuestionnaireResult />} />
        <Route path={`/${COMPANY}/${JIB}/${REJECT}`} element={<QuestionnaireRejectResult />} />
        <Route path={`/${COMPANY}/${EBI}/${RESULT}`} element={<QuestionnaireResult />} />
        <Route path={`/${COMPANY}/${EBI}/${REJECT}`} element={<QuestionnaireRejectResult />} />

        <Route path="*" element={<Navigate to={`/${BILLING}`} replace />} />
      </RouterRoutes>
      <Toaster />
    </Router>
  )
}
