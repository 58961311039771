import React from 'react'
import { BehaviorSubject } from 'rxjs'
import isEqual from 'lodash/isEqual'

export const globalContext = React.createContext({
  isLoading: new BehaviorSubject(false),
  errors: new BehaviorSubject({}),
  couponCode: new BehaviorSubject(''),
  isCouponCodeValid: new BehaviorSubject(false),
  planId: new BehaviorSubject(null),
  planData: new BehaviorSubject(null),
  corporateForm: new BehaviorSubject({}),
  freemiumForm: new BehaviorSubject({}),
  verificationEmail: new BehaviorSubject(null),
  couponData: new BehaviorSubject(null),
})

function identity(value) {
  return value
}

// Higher order hook for providing access to a single key of global state
export function createUseGlobalState(key) {
  return (fn = identity, deps = []) => {
    const context = React.useContext(globalContext)
    const withFn = React.useCallback(fn, [fn, ...deps])
    const stateObservable = context[key]
    const [localCopy, setLocalCopy] = React.useState(withFn(stateObservable.value))
    React.useEffect(() => {
      const sub = stateObservable.subscribe((...args) => {
        if (isEqual(localCopy, withFn(...args))) return
        setLocalCopy(withFn(...args))
      })
      return () => sub.unsubscribe()
    }, [stateObservable, localCopy, setLocalCopy, withFn])
    return localCopy
  }
}

function defaultStateMapper(setter, nextValue) {
  return setter(nextValue)
}

// Higher order hook for providing access to updating a single key of global state
export function createUseSetGlobalState(key, mapper = defaultStateMapper) {
  return (fn, deps) => {
    const withFn = React.useCallback(fn, [fn, ...deps])
    const context = React.useContext(globalContext)
    const stateObservable = context[key]
    const setter = React.useCallback(next => stateObservable.next(next), [stateObservable])
    return React.useCallback((...args) => {
      return mapper(setter, withFn(stateObservable.value, ...args))
    }, [setter, stateObservable, withFn])
  }
}

export const useIsLoading = createUseGlobalState('isLoading')
export const useSetIsLoading = createUseSetGlobalState('isLoading')

export const useErrors = createUseGlobalState('errors')
export const useSetErrors = createUseSetGlobalState('errors')

export const useCouponCode = createUseGlobalState('couponCode')
export const useSetCouponCode = createUseSetGlobalState('couponCode')

export const useIsCouponCodeValid = createUseGlobalState('isCouponCodeValid')
export const useSetIsCouponCodeValid = createUseSetGlobalState('isCouponCodeValid')

export const usePlanId = createUseGlobalState('planId')
export const useSetPlanId = createUseSetGlobalState('planId')

export const usePlanData = createUseGlobalState('planData')
export const useSetPlanData = createUseSetGlobalState('planData')

export const useCouponData = createUseGlobalState('couponData')
export const useSetCouponData = createUseSetGlobalState('couponData')

export const useCorporateForm = createUseGlobalState('corporateForm')
export const useSetCorporateForm = createUseSetGlobalState('corporateForm')

export const useFreemiumForm = createUseGlobalState('freemiumForm')
export const useSetFreemiumForm = createUseSetGlobalState('freemiumForm')

export const useVerificationEmail = createUseGlobalState('verificationEmail')
export const useSetVerificationEmail = createUseSetGlobalState('verificationEmail')
