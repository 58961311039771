import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LogoThrive } from 'assets/logo-thrive.svg'

export default React.memo(function ThriveLeftSideHeader() {
  return (
    <StyledWrapper>
      {<LogoThrive className='logo' />}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  .logo {
    width: 172px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }
`