import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import ReedemCoupon from './ReedemCoupon'
import get from 'lodash/get'
import SignUpForm from './SignUpForm'
import { usePlanData, useSetIsLoading, useSetPlanData, useCouponData } from 'hooks/useGlobalState'

import Loader from 'common/Loader'
import PlanPreview from './PlanPreview'
import PayPalForm from '../../PayPalForm'
import RightSectionLoader from 'common/RightSectionLoader'

import request from 'utils/request'

export default React.memo(function ThriveBillingForm() {
  const { search } = useLocation()
  const setIsLoading = useSetIsLoading((prev, next) => next, [])
  const planData = usePlanData()
  const setPlanData = useSetPlanData((prev, next) => next, [])

  const discontPaymentId = useCouponData(current => get(current, 'coupon.paypal_plan'))

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const plan = searchParams.get('plan')
    const billed = searchParams.get('billed')
    if (plan) {
      setIsLoading(true)
      request({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/patients/plans/${plan}`,
        params: {
          billed
        }
      })
        .then(({ data: { data } }) => {
          setIsLoading(false)
          setPlanData(data.plan)
        })
        .catch((error) => {
          setIsLoading(false)
          console.log(error)
        })
    }
  }, [search, setIsLoading, setPlanData])

  if (!planData) {
    return (
      <StyledWrapper >
        <Loader />
      </StyledWrapper>
    )
  }

  if (discontPaymentId === 'free') {
    return (
      <SignUpForm />
    )
  }

  return (
    <StyledWrapper>
      <div className='form-container'>
        <div>
          <div className='plan-title'>Join Thrive today!</div>
          <div className='plan-subtitle'>Don't waste another day feeling like something isn't quite right with your body. Let's discover why, and fix it together.</div>
        </div>

        <PlanPreview />

        <ReedemCoupon />
        <PayPalForm />
      </div>

      <RightSectionLoader />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  margin-left: 50%;
  width: 50%;
  padding: 128px 64px;
  margin-top: auto;
  margin-bottom: auto;

   @media screen and (max-width: 1024px) {
      padding: 36px 0px;
   }

  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .plan-title {
    color: #1E1B4B;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 4px;
  }
  .plan-subtitle {
    color: rgba(30, 27, 75, 0.80);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 64px;
    max-width: 600px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    .submit-button {
      margin-top: 2.5rem;
      padding: 1.6rem 5rem;
    }
  }
`
