import React from 'react'
import LinkButton from 'common/LinkButton'

import CouponForm from './CouponForm'

export default React.memo(function ReedemСoupon() {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleCoupon = React.useCallback(() => {
    setIsOpen((current) => !current)
  }, [setIsOpen])

  if (isOpen) return <CouponForm />

  return (
    <LinkButton clickHandler={toggleCoupon}>
      Redeem a coupon code
    </LinkButton>
  )
})
