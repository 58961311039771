export const LETTER_RE = /(?=.*[A-Za-z])/
export const NUMBER_RE = /(?=.*\d)/
export const SPECIAL_RE = /(?=.*[!@#$%^&*\-_=+"';:])/
export const UPPERCASE_RE = /(?=.*[A-Z])/

export default function validatePassword(password) {
  if (password.length === 0) {
    return {
      score: 0,
      isValid: false,
      message: 'The password field is required.'
    }
  }

  if (!LETTER_RE.test(password) || !NUMBER_RE.test(password)) {
    return {
      score: 1,
      isValid: false,
      hint: 'Password is too weak',
      message: 'Password must include at least one letter and one number.'
    }
  }

  if (!UPPERCASE_RE.test(password)) {
    return {
      score: 1,
      isValid: false,
      hint: 'Password is too weak',
      message: 'Password must include at least one uppercase letter.'
    }
  }

  if (!SPECIAL_RE.test(password)) {
    return {
      score: 2,
      isValid: false,
      hint: 'Password could be stronger',
      message: 'Add symbols'
    }
  }

  if (password.length < 9) {
    return {
      score: 2,
      isValid: false,
      hint: 'Password could be stronger',
      message: 'Password must be longer than 8 characters'
    }
  }

  if (password.length < 12) {
    return {
      score: 3,
      isValid: true,
      hint: 'Password is good but could be better'
    }
  }

  return {
    score: 4,
    isValid: true,
    hint: 'Strong password'
  }
}
