import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { usePlanData } from 'hooks/useGlobalState'

import Details from './Details'
import { toInteger } from 'lodash'

export default React.memo(function PlanPreview () {
  const planData = usePlanData()

  if (!planData) return null

  const title = get(planData, 'title', '')
  const description = get(planData, 'description', '')
  const price = get(planData, 'price', '')
  const billedLabel = get(planData, 'billed_label', '')
  const oneTimeFee = get(planData, 'one_time_fee', '')
  const show = toInteger(oneTimeFee) > 0;

  // Change billedLabel if it equals 'annual'
  const displayBilledLabel = billedLabel === 'annual' ? 'annually' : billedLabel;

  return (
    <StyledWrapper>
      <div className='g-d-flex'>
        <p className='title'>{title}</p>
      </div>

      <p className='description'>{description}</p>

      <div className='details-wrapper'>
        <div className='list-container'>
          { show && <p>${oneTimeFee} One time fee</p>}
          <p className='price-count'>{price}</p>
          <p className='hint'>Billed {displayBilledLabel}</p>
          <Details />
        </div>
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  padding: 2.5rem 3rem 3rem 2.5rem;
  margin-bottom: 10px;
  background: #fff;
  border: solid 1px #eaeaea;
  .title {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .description {
    margin-top: 0.5rem;
    font-size: 1.3rem;
  }
  .details-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1.5rem;
  }
  .list-container {
    flex-grow: 1;
  }
  .price-count {
    font-size: 2.4rem;
    font-weight: 700;
  }
  .hint {
    font-size: 1.4rem;
    opacity: 0.6;
  }
  .details {
    font-size: 1.5rem;
    color: #3242c6;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      text-decoration: none;
    }
  }
`

