import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { usePlanData } from 'hooks/useGlobalState'

export default function Discount () {
  const discount = usePlanData(current => get(current, 'discount'), [])
  if (!discount) return null
  return (
    <StyledWrapper>
      <div className='container'>
        <div className='count'>
          {discount}%
        </div>
        <div className='off'>
          off
        </div>
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background: rgba(240, 62, 61, 0.22);
  width: 12rem;
  height: 10rem;
  border-bottom-left-radius: 100%;
  .container {
    margin: 2rem 2rem 0 auto;
    text-align: center;
    .count {
      font-size: 3rem;
      line-height: 3rem;
    }
    .off {
      font-size: 1.6rem;
      text-transform: uppercase;
    }
  }
`
