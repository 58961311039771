import React from 'react'

import Template from 'components/GeneralPlans/Template'
import JoinLeftSide from 'components/GeneralPlans/left-sides/JoinLeftSide'
import FreemiumSignUpForm from 'components/freemium-sign-up/FreemiumSignUpForm'
import { CibaClassicTheme } from 'themes/cibaClassic'

export default function FreemiumSignUp() {
  return (
    <CibaClassicTheme>
      <Template>
        <JoinLeftSide
          title='Your selected package'
          subTitle='And preferable billing method'
        />
        <FreemiumSignUpForm />
      </Template>
    </CibaClassicTheme>
  )
}
