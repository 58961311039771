import React from 'react';
import { JibTheme } from 'themes/jib';
import EnrolmentHeader from './EnrolmentHeader';
import EnrolmentFooter from './EnrolmentFooter';
import { ReactComponent as Favicon } from 'assets/favicon.svg';

export default function QuestionnaireRejectResult() {

  const renderLeftSection = () => {
    return (
      <div className="flex flex-col lg:flex-row h-full mx-auto text-center my-auto pt-[56px] md:pt-12 gap-[56px] lg:gap-[99px]">
        <div className="flex items-center h-full w-full max-w-2xl">
          <div className="w-full mx-auto text-center">
            <Favicon className="w-[90px] lg:w-[128px] mx-auto mb-8" />
            <p className="text-[32px] font-bold text-deepPurple950 leading-snug mx-auto">
              We're sorry
            </p>
            <p className="text-baseRelaxed mb-[29px] mt-1 text-green950">
              Unfortunately, you're not eligible for a Ciba Health program. Reach out to us to learn more.
            </p>
            <div className="w-full mx-auto">
              <a href='https://cibahealth.com/contact-us'>
                <button
                  className="bg-deepPurple900 hover:bg-deepPurple950 border-transparent disabled:bg-gray-100 px-6 lg:px-8 py-3 lg:py-[11px] text-nowrap text-white rounded-full text-[14px] lg:text-[16px] font-semibold focus:shadow-lightBlue100"
                >
                  Contact Us
                </button>
              </a>
            </div>
          </div>
        </div>
      </div >
    )
  }

  return (
    <JibTheme>
      <div className='min-h-screen mx-auto flex flex-col justify-between items-center'>
        <div className="flex flex-col w-full min-h-screen justify-between mx-auto px-[30px] md:px-4 lg:px-9">
          <EnrolmentHeader />
          {renderLeftSection()}
          <EnrolmentFooter />
        </div>
      </div>
    </JibTheme>
  );
}
