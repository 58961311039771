import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'

import { EMAIL_VERIFICATION } from 'config/routes'

import {
  globalContext, useCorporateForm, useSetCorporateForm, useErrors, useSetErrors, useSetVerificationEmail
} from 'hooks/useGlobalState'

import FormTitle from 'common/FormTitle'
import InputWithLabel from 'common/InputWithLabel'
import CustomCheckbox from 'common/CustomCheckbox'
import CustomButton from 'common/CustomButton'

import WaitlistModal from './WaitlistModal'

import validatePassword from 'utils/validatePassword'
import request from 'utils/request'
import createNotification from 'utils/createNotification'

function existValidation(value) {
  return !!value
}

function emailValidation(value) {
  const emailRe = /\S+@\S+\.\S+/
  return emailRe.test(value)
}

export default function CorporateSignUpForm() {
  const navigate = useNavigate();
  const { search } = useLocation()
  const { corporateForm } = React.useContext(globalContext)
  const errors = useErrors()
  const setErrors = useSetErrors((prev, next) => next, [])
  const setVerificationEmail = useSetVerificationEmail((prev, next) => next, [])
  const [validate, setValidate] = React.useState(false)
  const [isSended, setIsSended] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen])

  const email = useCorporateForm(current => get(current, 'email', ''))
  const isEmailValid = emailValidation(email)
  const setEmail = useSetCorporateForm((prev, next) => ({ ...prev, email: next }), [])

  const password = useCorporateForm(current => get(current, 'password', ''))
  const passwordValidation = validatePassword(password)
  const isPasswordValid = passwordValidation.isValid
  const setPassword = useSetCorporateForm((prev, next) => ({ ...prev, password: next }), [])

  const firstName = useCorporateForm(current => get(current, 'first_name', ''))
  const isFirstNameValid = existValidation(firstName)
  const setFirstName = useSetCorporateForm((prev, next) => ({ ...prev, first_name: next }), [])

  const lastName = useCorporateForm(current => get(current, 'last_name', ''))
  const isLastNameValid = existValidation(lastName)
  const setLastName = useSetCorporateForm((prev, next) => ({ ...prev, last_name: next }), [])

  const coupon = useCorporateForm(current => get(current, 'coupon', ''))
  const isCouponValid = existValidation(coupon)
  const setCoupon = useSetCorporateForm((prev, next) => ({ ...prev, coupon: next }), [])

  const termsAgree = useCorporateForm(current => get(current, 'terms_agree', false))
  const setTermsAgree = useSetCorporateForm((prev, next) => ({ ...prev, terms_agree: next }), [])

  React.useEffect(() => {
    const params = new URLSearchParams(search)
    const coupon = params.get('coupon')
    if (coupon) setCoupon(coupon)
  }, [search, setCoupon])

  const submitHandler = React.useCallback((e) => {
    e.preventDefault()
    setValidate(true)
    setIsSended(true)
    request({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/patients/company/register`,
      data: corporateForm.value
    })
      .then(() => {
        setVerificationEmail(corporateForm.value.email)
        navigate(`/${EMAIL_VERIFICATION}`);
      })
      .catch((err) => {
        setErrors(err.response.data.errors)
        let type = get(err, 'response.data.type', '')
        if (type === 'capacity_error') {
          createNotification({
            message: get(err, 'response.data.message', ''),
            type: 'error'
          })
          openModal()
        }
      })
      .finally(() => setIsSended(false))
  }, [setValidate, setErrors, corporateForm, setIsSended, setVerificationEmail, navigate, openModal])

  return (
    <StyledWrapper>
      <FormTitle
        title='Sign up'
        subTitle='Please fill in this form to create new account'
      />

      <form onSubmit={submitHandler}>
        <InputWithLabel
          label='Email'
          placeholder='Enter your email'
          isRequred
          isValid={!validate || isEmailValid}
          value={email}
          changeHandler={setEmail}
          errors={errors['email']}
        />
        <InputWithLabel
          className='g-mt-15'
          label='Password'
          placeholder='Enter your password'
          type='password'
          isRequred
          isValid={!validate || isPasswordValid}
          value={password}
          changeHandler={setPassword}
          clientErrors={[passwordValidation.message]}
        />
        <InputWithLabel
          className='g-mt-15'
          label='First name'
          placeholder='Enter your first name'
          isRequred
          isValid={!validate || isFirstNameValid}
          value={firstName}
          changeHandler={setFirstName}
          errors={errors['first_name']}
        />
        <InputWithLabel
          className='g-mt-15'
          label='Last name'
          placeholder='Enter your last name'
          isRequred
          isValid={!validate || isLastNameValid}
          value={lastName}
          changeHandler={setLastName}
          errors={errors['last_name']}
        />
        <InputWithLabel
          className='g-mt-15'
          label='Company coupon code'
          placeholder='Enter your company coupon code'
          isRequred
          isValid={!validate || isCouponValid}
          value={coupon}
          changeHandler={setCoupon}
          errors={errors['coupon']}
        />
        <CustomCheckbox
          className='confirmation g-mt-15'
          label={(
            <p>
              <span>I am over 18 and agree to the </span>
              <a
                className='g-link'
                href='https://cibahealth.com/terms-of-use/'
                rel='noopener noreferrer'
                target='_blank'
              >
                terms of use
              </a>
              <span> and </span>
              <a
                className='g-link'
                href='https://cibahealth.com/privacy-policy/'
                rel='noopener noreferrer'
                target='_blank'
              >
                privacy policy
              </a>.
            </p>
          )}
          checked={termsAgree}
          changeHandler={setTermsAgree}
        />
        <CustomButton
          className='g-mt-15'
          disabled={!termsAgree || isSended}
          clickHandler={submitHandler}
        >
          Confirm
        </CustomButton>
      </form>
      <WaitlistModal
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: relative;
  margin-left: 50%;
  width: 50%;
  padding: 9% 0 9% 6.5%;
  @media screen and (max-width: 1000px) {
    margin-left: 0;
    width: 100%;
    padding: 2rem 4% 4.5rem;
  }
  .form-title {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  form {
    max-width: 40rem;
    margin-top: 3rem;
    @media screen and (max-width: 1000px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
      max-width: initial;
    }
    .confirmation {
      font-size: 1.2rem;
    }
  }
`
