import React from 'react'

import Template from 'components/ThrivePlan/Template'
import ThriveThankYouForm from 'components/ThriveThankYouForm'
import ThriveJoinLeftSide from 'components/ThrivePlan/left-sides/ThriveJoinLeftSide'
import { CibaClassicTheme } from 'themes/cibaClassic'

export default function ThriveThankYou() {
  return (
    <CibaClassicTheme>
      <Template>
        <ThriveJoinLeftSide />
        <ThriveThankYouForm />
      </Template>
    </CibaClassicTheme>
  )
}

