import React from 'react';

const FooterLink = ({ href, text }) => {
  return (
    <a
      href={href}
      className="text-gray700 hover:underline text-sm font-semibold text-nowrap"
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

const EnrolmentFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="flex w-full flex-col md:flex-row py-[40px] px-[24px] justify-between gap-3 text-left">
      <span className="text-gray500 text-2xs">
        © {currentYear} Ciba Health All Rights Reserved.
      </span>
      <div className="flex gap-[8px] mr-32">
        <FooterLink href="https://cibahealth.com/terms-of-use/" text="Terms of Use" />
        <FooterLink href="https://cibahealth.com/privacy-policy" text="Privacy Policy" />
      </div>
    </div>
  );
};

export default EnrolmentFooter;
