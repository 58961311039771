import axios from 'axios'

const fetchData = ({ url = '', method = 'get', params = {}, data = {} }) => {
  return axios({
    url,
    method,
    params,
    data,
  })
}

export default fetchData;
