import React from 'react'
import styled from 'styled-components'

export default React.memo(function Template ({ children }) {
  return (
    <StyledWrapper>
      {children}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  @media screen and (max-width: 1000px) {
    display: block;
  }
`
