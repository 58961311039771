import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { usePlanData } from 'hooks/useGlobalState'

import FormTitle from 'common/FormTitle'
import Details from './Details'

export default React.memo(function PlanPreview() {
  const planData = usePlanData()
  // const [isOpen, setIsOpen] = React.useState(true)

  // const toggleDetails = React.useCallback(() => {
  //   setIsOpen((current) => !current)
  // }, [setIsOpen])

  if (!planData) return null

  const isSinglePaymentOption = get(planData, 'is_single_payment_option', '')
  const title = get(planData, 'title', '')
  const programName = get(planData, 'program_name', '')
  const description = get(planData, 'description', '')
  const price = get(planData, 'price', '')
  const discountPrice = get(planData, 'discount_price', '')
  const billedLabel = get(planData, 'billed_label', '')

  return (
    <StyledWrapper>
      <FormTitle
        title={programName}
        subTitle={!isSinglePaymentOption ? 'And preferred billing method' : ''}
      />

      {title && (
        <div className='g-d-flex g-mt-10'>
          <StyledIcon className='icon' />
          <p className='title'>{title}</p>
        </div>
      )}

      <p className='description'>{description}</p>

      <div className='details-wrapper'>
        <div className='list-container'>
          <p className='price-count'>
            <span>
              {discountPrice || price}
            </span>
            <span className='old-price'>
              {discountPrice && price}
            </span>
          </p>
          <p className='hint'>Billed {billedLabel}</p>
          {/* {isOpen && <Details />} */}
          <Details />
        </div>

        {/* <p
          className='details'
          onClick={toggleDetails}
        >
          {isOpen ? 'Hide' : 'Details'}
        </p> */}
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  .form-title {
    max-width: 85%;
  }
  .icon {
    flex-shrink: 0;
  }
  .title {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .description {
    margin-top: 1rem;
    font-size: 1.3rem;
  }
  .details-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1.5rem;
  }
  .list-container {
    flex-grow: 1;
  }
  .price-count {
    font-size: 2.4rem;
    font-weight: 700;
  }
  .old-price {
    color: #f03e3d;
    font-size: 1.6rem;
    margin-left: 2rem;
    text-decoration: line-through;
  }
  .hint {
    font-size: 1.4rem;
    opacity: 0.6;
  }
  .details {
    font-size: 1.5rem;
    color: #3242c6;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      text-decoration: none;
    }
  }
`

const StyledIcon = styled.div`
  position: relative;
  align-self: center;
  width: 2rem;
  height: 2rem;
  background: #fab005;
  border-radius: 50%;
  margin-right: 1rem;
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: solid 2px #fff;
    border-top: none;
    border-left: none;
    width: 0.4rem;
    height: 1rem;
    transform: rotate(45deg) translate(-1px, -1px);
  }
`
