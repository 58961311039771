import React from 'react'
import styled from 'styled-components'

import Modal from 'common/Modal'

export default function WaitlistModal ({ isOpen, closeModal }) {
  React.useEffect(() => {
    if (isOpen) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://cibahealth.activehosted.com/f/embed.php?id=37'
      document.body.appendChild(script)
    }
  }, [isOpen])
  if (!isOpen) return null
  return (
    <Modal
      closeModal={closeModal}
    >
      <StyledWrapper>
        <div className='_form_37' />
      </StyledWrapper>
    </Modal>
  )
}

const StyledWrapper = styled.div`
  ._form-title {
    font-size: 2rem !important;
  }
  ._html-code > p {
    font-size: 1.3rem !important;
  }
  #_form_6246DCEB6A6CE_ ._form_element._clear {
    margin-bottom: 1rem !important;
  }
  ._form_37 {
    form {
      width: 100% !important;
      padding: 0 !important;
      margin: 1.5rem 0 0 0 !important;
    }
    input,
    select,
    textarea {
      font-size: 1.3rem !important;
      line-height: 3rem !important;
      outline: none;
      padding: 0.8rem 1.6rem !important;
      border: solid 1px #e9ebef !important;
      border-radius: 2px !important;
      &:hover:enabled {
        border-color: #cccccc !important;
      }
      &:focus {
        box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
      }
    }
  }
  ._form_element {
    margin-bottom: 1.5rem !important;
  }
  ._form-label {
    margin-bottom: 1rem !important;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
  }
  #_form_37_submit._submit {
    margin-top: 3.6rem;
    font-family: 'Poppins', sans-serif !important;
    background: #16123f !important;
    border: solid 2px #16123f !important;
    font-size: 1.3rem !important;
    transition: all 0.2s;
    padding: 1.1rem 4rem !important;
    line-height: 1.3rem !important;
    border-radius: 2.4rem !important;
    &:hover {
      background: #ffffff !important;
      color: #16123f !important;
    }
  }
`
