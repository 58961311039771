import React from 'react'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'

import { Auth } from 'aws-amplify'
import { CORPORATE_SIGN_UP } from 'config/routes'

import { useVerificationEmail } from 'hooks/useGlobalState'

import CustomButton from 'common/CustomButton'

import createNotification from 'utils/createNotification'

export default function VerificationContent() {
  const [isSended, setIsSended] = React.useState(false)
  const email = useVerificationEmail()

  const sendAgain = React.useCallback(() => {
    setIsSended(true)
    Auth.resendSignUp(email)
      .then(() => {
        createNotification({
          message: 'Verification email successfully sended',
          type: 'success'
        })
      })
      .catch(() => {
        createNotification({
          message: 'Something went wrong',
          type: 'error'
        })
      })
      .finally(() => setIsSended(false))
  }, [email, setIsSended])

  if (!email) return <Navigate to={`/${CORPORATE_SIGN_UP}`} />;

  return (
    <StyledWrapper>
      <div className='wrapper'>
        <p className='title'>Email verification</p>
        <p className='content g-mt-15'>We sent an email to <b>{email}</b></p>
        <p className='content'>Follow the instructions in the email.</p>
        <p className='content g-mt-35'>
          <span>Didn't receive an email? Feel free to </span>
          <a
            className='link'
            href='https://cibahealth.com/contact-us/'
            rel='noopener noreferrer'
            target='_blank'
          >
            contact us
          </a>
        </p>

        <CustomButton
          className='g-mt-50'
          disabled={isSended}
          clickHandler={sendAgain}
        >
          Send again
        </CustomButton>
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: relative;
  margin-left: 50%;
  width: 50%;
  padding: 9% 0 9% 6.5%;
  @media screen and (max-width: 1000px) {
    margin-left: 0;
    width: 100%;
    padding: 2rem 4% 4.5rem;
  }
  .wrapper {
    max-width: 40rem;
    @media screen and (max-width: 1000px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
      max-width: initial;
    }
  }
  .title {
    font-size: 2.4rem;
    font-weight: 700;
  }
  .content {
    font-size: 1.6rem;
    font-weight: 400;
  }
  .link {
    color: #3242c6;
  }
`
