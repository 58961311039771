import React from 'react'
import styled from 'styled-components'

import { useIsLoading } from 'hooks/useGlobalState'

export default function Loader () {
  const isLoading = useIsLoading()

  if (!isLoading) return null

  return (
    <StyledWrapper>
      <div className='lds-ring'>
        <div className='lds-ring-back' />
        <div />
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`
