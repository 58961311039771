import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'

import { BILLING } from 'config/routes'

import { usePlanData } from 'hooks/useGlobalState'

const paymentPeriods = [
  { value: 'one-time', label: 'Save 15% with one-time payment' },
  { value: 'monthly', label: 'Monthly payment' }
]

export default function PaymentSwitcher () {
  const { search } = useLocation()
  const isSinglePaymentOption = usePlanData(current => get(current, 'is_single_payment_option'))

  const { label, url } = React.useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const billed = searchParams.get('billed')
    const length = paymentPeriods.length
    const currentIndex = paymentPeriods.findIndex(item => item.value === billed)
    if (!~currentIndex) return {}
    const nextIndex = currentIndex + 1 < length ? currentIndex + 1 : 0
    searchParams.set('billed', paymentPeriods[nextIndex].value)
    return {
      label: paymentPeriods[nextIndex].label,
      url: `/${BILLING}?${searchParams.toString()}`
    }
  }, [search])

  if (!url || isSinglePaymentOption) return null

  return (
    <StyledLink to={url}>
      {label}
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  align-self: flex-start;
  margin-top: 1rem;
  font-size: 1.3rem;
  color: #3242c6;
  :hover {
    text-decoration: none;
  }
`
