import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CibaClassicTheme } from 'themes/cibaClassic'

import { EMAIL_VERIFIED } from "config/routes";

import request from "../utils/request";

export default function CorporateVerify() {
  const navigate = useNavigate();
  const { search } = useLocation();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const name = searchParams.get("user_name");
    const code = searchParams.get("code");

    if (name && code) {
      request({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/patients/verify-email`,
        data: {
          username: name,
          code: code
        }
      })
        .then(() => navigate(`/${EMAIL_VERIFIED}`))
        .catch(() => {
          window.location.replace(`${process.env.REACT_APP_ENV_URL}/login?verlinkexp=1`);
        });
    } else {
      window.location.replace(`${process.env.REACT_APP_ENV_URL}/login?verlinkexp=1`);
    }

  }, [navigate, search]);

  return (
    <CibaClassicTheme>
      <div className="loader-container">
        <div className="lds-ring">
          <div className="lds-ring-back" />
          <div />
        </div>
      </div>
    </CibaClassicTheme>
  );
}
