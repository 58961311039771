import React from 'react';
import Questionnaire from '../Questionnaire';

const EBI_FORMID = process.env.REACT_APP_EBI_FORMID;

const EBIpage = () => {
  return (
    <Questionnaire formId={EBI_FORMID} />
  )
}

export default EBIpage