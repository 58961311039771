import React from 'react'
import styled from 'styled-components'

import { usePlanData } from 'hooks/useGlobalState'

const Detail = React.memo(function Detail ({ text, tooltip }) {
  return (
    <StyledItem>
      <span className='icon' />
      <span className='text'>{text}</span>
      {tooltip && <span className='tooltip'>{tooltip}</span>}
    </StyledItem>
  )
})

export default React.memo(function Details () {
  const planData = usePlanData()

  const details = React.useMemo(() => {
    if (!planData) return null
    const parsedDetails = planData.details.map(({ text, tooltip }, i) => {
      return (
        <Detail
          key={i}
          text={text}
          tooltip={tooltip}
        />
      )
    })
    return (
      <StyledList>
        {parsedDetails}
      </StyledList>
    )
  }, [planData])

  return details
})

const StyledItem = styled.li`
  position: relative;
  display: flex;
  cursor: pointer;
  :hover {
    .tooltip {
      opacity: 1;
      z-index: 1;
    }
  }
  .icon {
    align-self: center;
    display: block;
    width: 0.5rem;
    height: 1rem;
    border: solid 2px #fab005;
    border-top: none;
    border-left: none;
    margin-right: 1rem;
    transform: rotate(45deg);
  }
  .text {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-bottom: dotted 1px rgba(50, 50, 50, 0.11);
  }
  .tooltip {
    position: absolute;
    bottom: 25px;
    left: 0;
    border-radius: 2px;
    border: solid 1px #e0e0e0;
    background-color: #f9f9f9;
    padding: 10px 17px 14px;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    transition: all .35s ease;
    opacity: 0;
    z-index: -1;
  }
`

const StyledList = styled.ul`
  list-style-type: none;
`
