import React from 'react'
import styled from 'styled-components'

export default React.memo(function CustomCheckbox ({
  className = '', checked, changeHandler, label
}) {
  const handleChange = React.useCallback(e => changeHandler(e.target.checked), [changeHandler])

  return (
    <StyledWrapper className={className}>
      {label}
      <input
        checked={checked}
        onChange={handleChange}
        type='checkbox'
      />
      <span className='checkmark' />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.label`
  display: block;
  position: relative;
  padding-left: 3rem;
  font-size: 1.3rem;
  line-height: 2rem;
  user-select: none;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 5px;
    border: solid 1px #ccc;
    background-color: #fff;
  }

  input:checked ~ .checkmark {
    background: #16123f;
    border-color: #16123f;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 0.5rem;
    top: 0.2rem;
    width: 0.3rem;
    height: 0.7rem;
    border: solid #fff;
    border-width: 0 0.2rem 0.2rem 0;
    transform: rotate(45deg);
    @media screen and (max-width: 480px) {
      left: 0.4rem;
    }
  }
`
