import React from 'react'
import styled from 'styled-components'

import { useIsLoading } from 'hooks/useGlobalState'

export default React.memo(function RightSectionLoader () {
  const isLoading = useIsLoading()

  if (!isLoading) return null

  return (
    <StyledWrapper>
      <div className='loader-container'>
        <div className='lds-ring'>
          <div className='lds-ring-back' />
          <div />
        </div>
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 100;
  .loader-container {
    background: #fff;
    position: fixed;
    left: 50%;
    width: 50%;
    @media screen and (max-width: 1000px) {
      position: absolute;
      left: 0;
      width: 100%;
    }
  }
  .lds-ring {
    width: 6rem;
    height: 6rem;
  }
  .lds-ring div {
    width: 5.4rem;
    height: 5.4rem;
    border-width: 0.8rem;
  }
  .lds-ring-back {
    border: solid 0.8rem #e9ebef;
  }
`
