import React from 'react';
import Questionnaire from '../Questionnaire';

const JIB_FORMID = process.env.REACT_APP_JIB_FORMID;

const JIBpage = () => {
  return (
    <Questionnaire formId={JIB_FORMID} />
  )
}

export default JIBpage