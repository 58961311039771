import React from 'react'

import Template from 'components/GeneralPlans/Template'
import JoinLeftSide from 'components/GeneralPlans/left-sides/JoinLeftSide'
import CorporateSignUpForm from 'components/corporate-sign-up/CorporateSignUpForm'
import { CibaClassicTheme } from 'themes/cibaClassic'

export default function CorporateSignUp() {
  return (
    <CibaClassicTheme>
      <Template>
        <JoinLeftSide
          title='Your selected package'
          subTitle='And preferred billing method'
        />
        <CorporateSignUpForm />
      </Template>
    </CibaClassicTheme>
  )
}
