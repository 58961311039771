import React from 'react'

import Template from 'components/GeneralPlans/Template'
import WelcomeLeftSide from 'components/GeneralPlans/left-sides/WelcomeLeftSide'
import ThankYouForm from 'components/ThankYouForm'
import { CibaClassicTheme } from 'themes/cibaClassic'

export default function ThankYou() {
  return (
    <CibaClassicTheme>
      <Template>
        <WelcomeLeftSide />
        <ThankYouForm />
      </Template>
    </CibaClassicTheme>
  )
}
