export const SIGN_UP = 'sign-up'
export const BILLING = 'billing'
export const PROGRAMS = 'programs'
export const THANK_YOU = 'thank-you'
export const THRIVE_THANK_YOU = 'thrive-thank-you'
export const CORPORATE_SIGN_UP = 'corporate-sign-up'
export const REFERRAL_SIGN_UP = 'referral-sign-up'
export const EMAIL_VERIFICATION = 'email-verification'
export const CORPORATE_VERIFY = 'corporate-verify'
export const EMAIL_VERIFIED = 'email-verified'
export const COMPANY = 'company'
export const JIB = 'jib'
export const EBI = 'ebi'
export const RESULT = 'result'
export const REJECT = 'reject'
