import React from 'react'

import Template from 'components/GeneralPlans/Template'
import JoinLeftSide from 'components/GeneralPlans/left-sides/JoinLeftSide'
import VerificationContent from 'components/email-varification/VerificationContent'
import { CibaClassicTheme } from 'themes/cibaClassic'

export default function EmailVarification() {
  return (
    <CibaClassicTheme>
      <Template>
        <JoinLeftSide
          title='Your selected package'
          subTitle='And preferred billing method'
        />
        <VerificationContent />
      </Template>
    </CibaClassicTheme>
  )
}
