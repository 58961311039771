import React from 'react'
import styled from 'styled-components'

import programBack from 'assets/program-back.jpg'

import { ReactComponent as Logo } from 'assets/logo.svg'

import ProgramsForm from 'components/programs/ProgramsForm'
import { CibaClassicTheme } from 'themes/cibaClassic'

export default function Programs() {
  return (
    <CibaClassicTheme>
      <StyledWrapper>
        <div className='wrapper'>
          <Logo className='logo' />
          <ProgramsForm />
        </div>
      </StyledWrapper>
    </CibaClassicTheme>
  )
}

const StyledWrapper = styled.div`
  min-height: 100vh;
  background: url(${programBack}) no-repeat;
  background-size: cover;
  padding: 6rem 0;
  .wrapper {
    max-width: 58rem;
    margin: 0 auto;
    .logo {
      display: block;
      margin: 0 auto;
    }
  }
`
