import React from 'react'
import styled from 'styled-components'

export default React.memo(function FormTitle ({ title = '', subTitle = '' }) {
  return (
    <div className='form-title'>
      <StyledTitle>
        {title}
      </StyledTitle>
      {subTitle && (
        <StyledSubTitle>
          {subTitle}
        </StyledSubTitle>
      )}
    </div>
  )
})

const StyledTitle = styled.p`
  font-size: 2.4rem;
  font-weight: 700;
`

const StyledSubTitle = styled.p`
  font-size: 1.5rem;
`
