import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import { THRIVE_THANK_YOU } from 'config/routes'
import { useCouponCode } from 'hooks/useGlobalState'
import FormTitle from 'common/FormTitle'
import InputWithLabel from 'common/InputWithLabel'
import CustomButton from 'common/CustomButton'
import RightSectionLoader from 'common/RightSectionLoader'
import CustomCheckbox from 'common/CustomCheckbox'
import request from 'utils/request'

export default React.memo(function SignUpForm() {
  const navigate = useNavigate();
  const couponCode = useCouponCode()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [confirmation, setConfirmation] = useState(false)

  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const firstNameValidation = useMemo(() => firstName.length !== 0, [firstName])
  const lastNameValidation = useMemo(() => lastName.length !== 0, [lastName])
  const emailValidation = useMemo(() => {
    const emailRe = /\S+@\S+\.\S+/
    return emailRe.test(email)
  }, [email])

  const isFormValid = useMemo(() => {
    return firstNameValidation && lastNameValidation && emailValidation
  }, [firstNameValidation, lastNameValidation, emailValidation])

  const submitHandler = useCallback((e) => {
    e.preventDefault()
    setErrors({})
    setIsLoading(true)

    request({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/patients/program/register`,
      data: {
        terms_agree: true,
        coupon: couponCode,
        email,
        first_name: firstName,
        last_name: lastName,
      }
    })
      .then(({ data: { data } }) => {
        setIsLoading(false)
        navigate(`/${THRIVE_THANK_YOU}`);
      })
      .catch((err) => {
        setIsLoading(false)
        setErrors(err.response.data.errors)
      })
  }, [couponCode, email, firstName, lastName, navigate])

  return (
    <StyledWrapper>
      <FormTitle
        title="Let's get started"
        subTitle='Create your Ciba account and become a member'
      />

      <form onSubmit={submitHandler}>
        <InputWithLabel
          label='First name'
          placeholder='Enter your first name'
          isValid={firstNameValidation}
          value={firstName}
          changeHandler={setFirstName}
          errors={errors['first_name']}
        />
        <InputWithLabel
          className='g-mt-15'
          label='Last name'
          placeholder='Enter your last name'
          isValid={lastNameValidation}
          value={lastName}
          changeHandler={setLastName}
          errors={errors['last_name']}
        />
        <InputWithLabel
          className='g-mt-15'
          label='Email'
          placeholder='Enter your email'
          isValid={emailValidation}
          type='email'
          value={email}
          changeHandler={setEmail}
          errors={errors['email']}
        />

        <CustomCheckbox
          className='g-mt-15'
          label={(
            <p>
              <span>By checking this box, I confirm I have read and agree to the </span>
              <a className='g-link' target='_blank' rel="noopener noreferrer" href='https://cibahealth.com/privacy-policy/'>Privacy Policy</a>
              <span> and </span>
              <a className='g-link' target='_blank' rel="noopener noreferrer" href='https://cibahealth.com/terms-of-use/'>Terms of Use</a>.
            </p>
          )}
          checked={confirmation}
          changeHandler={setConfirmation}
        />

        <CustomButton
          className='g-mt-35'
          disabled={!isFormValid || isLoading || !confirmation}
          clickHandler={submitHandler}
        >
          {isLoading ? 'Loading...' : 'Next'}
        </CustomButton>

      </form>

      <RightSectionLoader />
    </StyledWrapper>
  )
})


const StyledWrapper = styled.div`
  position: relative;
  margin-left: 50%;
  width: 50%;
  padding: 128px 64px;
  margin-top: auto;
  margin-bottom: auto;

   @media screen and (max-width: 1024px) {
      padding: 36px 0px;
   }

  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-title {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  form {
    max-width: 40rem;
    margin-top: 3rem;
    @media screen and (max-width: 1000px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
      max-width: initial;
    }
  }
  .form-footer {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .plan-title {
    color: #1E1B4B;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 4px;
  }
  .plan-subtitle {
    color: rgba(30, 27, 75, 0.80);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 64px;
    max-width: 600px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    .submit-button {
      margin-top: 2.5rem;
      padding: 1.6rem 5rem;
    }
  }
`
